import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { FC, FocusEvent, Fragment, useState, useEffect } from "react";

import { Operations } from "~/api";

export type TagSelectProps = {
  tag?: Components.Schemas.Tag;
  onChange: (newAccount: Components.Schemas.Tag) => void;
  disabled?: boolean;
  className?: string;
  allow_new?: boolean;
  include_inactive?: boolean;
};

export const TagSelect: FC<TagSelectProps> = ({
  tag,
  onChange,
  className,
  allow_new = false,
  include_inactive = false,
  ...rest
}) => {
  const { data: tags } = Operations.useTags();

  const [selectedTag, setSelectedTag] = useState<Components.Schemas.Tag | null>(null);
  const [query, setQuery] = useState("");

  // Update internal state when tag prop changes
  useEffect(() => {
    setSelectedTag(tag || null);
    setQuery(tag?.name || "");
  }, [tag]);

  function exists(name: string) {
    return !!tags!.find((t) => t.name == name);
  }

  return (
    <Combobox
      value={selectedTag}
      onChange={(tag) => {
        if (!tag) {
          setSelectedTag(null);
          return;
        }

        setSelectedTag(tag);
        onChange(tag);
      }}
      {...rest}
    >
      <div className={cn("relative w-full", className)}>
        <div className="relative w-full cursor-default bg-white text-left">
          <ComboboxInput
            className={cn(
              "bg-primary px-2 py-1 border w-full pr-8",
              "focus:outline-none focus:shadow-outline-normal",
            )}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(tag: Components.Schemas.Tag | null) => tag?.name || ""}
            autoComplete="off"
            autoCorrect="off"
            placeholder="Tag"
            onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
          />
          <ComboboxButton
            className="absolute inset-y-0 right-0 flex items-center pr-2"
            onClick={() => setQuery("")}
          >
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow focus:outline-none sm:text-sm">
            {allow_new && query && !exists(query) && (
              <ComboboxOption key={0} value={{ name: query }} as={Fragment}>
                {({ focus }) => (
                  <div
                    className={cn("truncate whitespace-nowrap p-1 ", {
                      "bg-blue-500 text-white": focus,
                    })}
                  >
                    <div>{query}</div>
                  </div>
                )}
              </ComboboxOption>
            )}

            {tags
              ?.filter((a) => a.name?.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
              .filter((t) => !(selectedTag === t))
              .filter((t) => (include_inactive ? true : t.is_active))
              .map((a) => (
                <ComboboxOption key={a.id} value={a} as={Fragment} disabled={!a.is_active}>
                  {({ focus }) => (
                    <div
                      className={cn("truncate whitespace-nowrap p-1 ", {
                        "bg-blue-500 text-white": focus,
                        "bg-red-300 ": !a.is_active,
                      })}
                    >
                      <div>{a.name}</div>
                    </div>
                  )}
                </ComboboxOption>
              ))}
          </ComboboxOptions>
        </Transition>
      </div>
    </Combobox>
  );
};
