import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Transition } from "@headlessui/react";
import { Bars4Icon } from "@heroicons/react/20/solid";
import cn from "classnames";
import React, { FC, PropsWithChildren, Fragment } from "react";
import Draggable from "react-draggable";

import { Cross } from "~/components/icons";

interface MyModalProps {
  open: boolean;
  onClose: (shouldRefresh: boolean) => void;
  title: string;
  size?: string;
}

const MyModal: FC<React.PropsWithChildren<PropsWithChildren<MyModalProps>>> = ({
  title,
  open,
  onClose,
  children,
  size = "w-full sm:max-w-xl",
}) => {
  return (
    <Dialog
      className="fixed inset-0 z-20 flex items-center justify-center"
      onClose={() => onClose(false)}
      open={open}
      aria-labelledby="dialog-title"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <Transition
        appear
        show={open}
        enter="transition duration-100 ease-out"
        enterFrom="scale-95 opacity-0"
        enterTo="scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="scale-100 opacity-100"
        leaveTo="scale-95 opacity-0"
      >
        <div className={cn(size, "z-10")}>
          <DialogPanel className="grid max-h-screen grid-rows-[min-content,minmax(0,100%)] rounded bg-white shadow-xl">
            <DialogTitle
              as="h3"
              id="dialog-title"
              className="flex items-center justify-between rounded-t bg-gray-700 p-6 text-lg font-medium leading-6 text-white"
            >
              <div className="flex items-center">
                <div id="handle" className="mr-2 inline-block w-5 cursor-grab text-white">
                  <Bars4Icon />
                </div>
                {title}
              </div>
              <Cross className="cursor-pointer" onClick={() => onClose(false)} />
            </DialogTitle>
            <div className="m-4 overflow-y-auto ">{children}</div>
          </DialogPanel>
        </div>
      </Transition>
    </Dialog>
  );
};

export default MyModal;
