import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cn from "classnames";
import React, { FC, useEffect, useRef } from "react";

interface SidebarProps {
  children: any;
  onClose: () => void;
}

const Sidebar: FC<React.PropsWithChildren<SidebarProps>> = ({ children, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sidebar = ref.current;

    if (sidebar) {
      disableBodyScroll(sidebar, { reserveScrollBarGap: true });
    }
    return () => {
      if (sidebar) enableBodyScroll(sidebar);
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <div className="fixed inset-0 h-full z-50 box-border">
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute inset-0 bg-black bg-opacity-40 duration-100 ease-linear backdrop-blur-sm"
          onClick={onClose}
        />
        <section className="absolute inset-y-0 right-0 flex max-w-full pl-10 outline-none">
          <div className="h-full md:w-screen md:max-w-md">
            <div
              className="h-full flex flex-col text-base bg-accent-0 shadow-xl overflow-y-auto overflow-x-hidden"
              ref={ref}
            >
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Sidebar;
