import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import cn from "classnames";
import React, { FC, Fragment, useState, useEffect } from "react";

import { Operations } from "~/api";

interface SelectTagsProps {
  type: "payee" | "memo";
  value: string;
  onChange: (text: string) => void;
  className?: string;
}

function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const TextAutoComplete: FC<React.PropsWithChildren<SelectTagsProps>> = ({
  value,
  onChange,
  type,
  className,
}) => {
  const [selectedText, setSelectedText] = useState({ text: "", count: 1 });
  const [query, setQuery] = useState("");

  // Update internal state when value prop changes
  useEffect(() => {
    setSelectedText({ text: value, count: 1 });
    setQuery(value);
  }, [value]);

  const filteredSuggestions = Operations.useHint(type, query);

  return (
    <Combobox
      value={selectedText}
      onChange={(hint) => {
        if (hint) {
          setSelectedText(hint);
          return onChange(hint.text);
        }
      }}
    >
      <div className={cn("relative w-full", className)}>
        <div className="relative w-full cursor-default bg-white ">
          <ComboboxInput
            className="w-full border bg-primary px-2 py-1 focus:shadow-outline-normal focus:outline-none"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(hint: Components.Schemas.Hint) => hint.text}
            placeholder={capitalizeFirstLetter(type)}
          />
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base focus:outline-none sm:text-sm">
            <ComboboxOption value={{ text: query, count: 1 }} as={Fragment}>
              {({ focus, selected }) => (
                <div
                  className={cn("truncate whitespace-nowrap  p-1", {
                    "bg-blue-500 text-white": focus,
                    "bg-white text-black": !focus,
                    "font-bold": selected,
                  })}
                >
                  {query ? query : `Clear ${type}`}
                </div>
              )}
            </ComboboxOption>

            {filteredSuggestions.data
              ?.filter((item) => item.text != query)
              .map((hint) => (
                <ComboboxOption key={hint.text} value={hint} as={Fragment}>
                  {({ focus, selected }) => (
                    <div
                      className={cn("truncate whitespace-nowrap p-1", {
                        "bg-blue-500 text-white": focus,
                        "bg-white text-black": !focus,
                        "font-bold": selected,
                      })}
                    >
                      {hint.text} {hint.count}
                    </div>
                  )}
                </ComboboxOption>
              ))}
          </ComboboxOptions>
        </Transition>
      </div>
    </Combobox>
  );
};
