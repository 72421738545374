import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import dayjs from "dayjs";
import findIndex from "lodash/findIndex";
import React, { Fragment, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { ChevronLeft, ChevronRight } from "~/components/icons";
import { OperationsPageQueryParams } from "~/pages/operations";

interface DateRangePickerProps {
  range: OperationsPageQueryParams["range"];
  after?: dayjs.Dayjs;
  before?: dayjs.Dayjs;
  onChange: (
    range?: OperationsPageQueryParams["range"],
    after?: dayjs.Dayjs,
    before?: dayjs.Dayjs,
  ) => void;
}

const values = [
  { title: "Anytime" },
  { title: "This month", value: "tm" },
  { title: "This year", value: "ty" },
  { title: "Last 3 months", value: "3m" },
  { title: "Last 6 months", value: "6m" },
  { title: "Last 12 months", value: "12m" },
];

const DateRangePicker: React.FC<React.PropsWithChildren<DateRangePickerProps>> = ({
  range,
  after,
  before,
  onChange,
}) => {
  function handleChange(range: OperationsPageQueryParams["range"]) {
    onChange(range);
  }

  function isShiftAllowed() {
    return after && before;
  }

  function shift(direction: "add" | "subtract") {
    if (!after || !before) return;

    const diff = before.diff(after, "months") + 1;

    onChange(
      undefined,
      after[direction](diff, "months"),
      before[direction](diff, "months").endOf("month"),
    );
  }

  function prevPeriod() {
    shift("subtract");
  }

  function nextPeriod() {
    shift("add");
  }

  useHotkeys("[", prevPeriod);
  useHotkeys("]", nextPeriod);

  function buttonLabel(after: dayjs.Dayjs | undefined, before: dayjs.Dayjs | undefined) {
    const format = "MMM D, YYYY";

    if (after && before) {
      return `${after.format(format)} - ${before.format(format)}`;
    } else if (after) {
      return `after ${after.format(format)}`;
    } else if (before) {
      return `before ${before.format(format)}`;
    } else {
      return "anytime";
    }
  }

  return (
    <div className="relative w-full cursor-default rounded bg-white text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
      <Listbox value={range} onChange={handleChange}>
        <div className="relative">
          <div className="flex">
            {isShiftAllowed() && (
              <button
                disabled={!isShiftAllowed()}
                className="flex cursor-pointer items-center hover:bg-gray-100 disabled:opacity-0"
                onClick={prevPeriod}
              >
                <ChevronLeft className="size-5" />
              </button>
            )}
            <ListboxButton className="w-full p-2 text-center hover:bg-gray-100">
              <span className="block w-full truncate">{buttonLabel(after, before)}</span>
            </ListboxButton>
            {isShiftAllowed() && (
              <button
                className="inset-y-0 flex cursor-pointer items-center hover:bg-gray-100"
                onClick={nextPeriod}
              >
                <ChevronRight className="size-5" />
              </button>
            )}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {values.map((range, index) => (
                <ListboxOption
                  key={index}
                  value={range.value}
                  className={({ focus }) =>
                    `${focus ? "text-gray-900 bg-gray-100" : "text-gray-900"}
                      cursor-default select-none relative py-2 pl-4 pr-4`
                  }
                >
                  {({ selected, focus }) => (
                    <span className={`${selected ? "font-medium" : "font-normal"} block truncate`}>
                      {range.title}
                    </span>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default DateRangePicker;
