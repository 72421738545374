import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { FC, useCallback, useEffect, useRef } from "react";

import { Cross } from "~/components/icons";
import FocusTrap from "~/utils/focusTrap";

interface ModalProps {
  className?: string;
  children?: any;
  onClose: () => void;
  onEnter?: () => void | null;
}

const Modal: FC<React.PropsWithChildren<ModalProps>> = ({ children, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        return onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    const modal = ref.current;

    if (modal) {
      disableBodyScroll(modal, { reserveScrollBarGap: true });
      window.addEventListener("keydown", handleKey);
    }
    return () => {
      if (modal) {
        enableBodyScroll(modal);
      }
      clearAllBodyScrollLocks();
      window.removeEventListener("keydown", handleKey);
    };
  }, [handleKey]);

  return (
    <div className="fixed bg-black bg-opacity-40 flex items-center inset-0 z-50 justify-center backdrop-blur-sm">
      <div className="bg-primary p-12 border border-accent-2 relative" role="dialog" ref={ref}>
        <button
          onClick={() => onClose()}
          aria-label="Close panel"
          className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-6"
        >
          <Cross className="size-6" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
