import React from "react";

const LoadingDots: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <span className="inline-flex text-center items-center leading-7">
      <span
        className="rounded-full h-2 w-2 bg-current animate-blink mx-0.5"
        style={{ animationDelay: "0s" }}
        key={`dot_1`}
      />
      <span
        className="rounded-full h-2 w-2 bg-current animate-blink mx-0.5"
        style={{ animationDelay: "0.2s" }}
        key={`dot_2`}
      />
      <span
        className="rounded-full h-2 w-2 bg-current animate-blink mx-0.5"
        style={{ animationDelay: "0.4s" }}
        key={`dot_3`}
      />
    </span>
  );
};

export default LoadingDots;
