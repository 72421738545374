import cn from "classnames";
import React, { FunctionComponent, JSXElementConstructor, CSSProperties } from "react";

interface TextProps {
  variant?: Variant;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode | any;
  html?: string;
  onClick?: () => any;
}

type Variant = "heading" | "body" | "pageHeading" | "sectionHeading";

const Text: FunctionComponent<React.PropsWithChildren<TextProps>> = ({
  style,
  className = "",
  variant = "body",
  children,
  html,
  onClick,
}) => {
  const componentsMap: {
    [P in Variant]: React.ComponentType<React.PropsWithChildren<any>> | string;
  } = {
    body: "div",
    heading: "h1",
    pageHeading: "h1",
    sectionHeading: "h2",
  };

  const Component:
    | JSXElementConstructor<any>
    | React.ReactElement<any>
    | React.ComponentType<React.PropsWithChildren<any>>
    | string = componentsMap![variant!];

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {};

  const variantClasses = {
    body: "text-base leading-7 max-w-6xl mx-auto",
    heading: "text-5xl pt-1 pb-2 font-semibold tracking-wide cursor-pointer mb-2",
    pageHeading: "pt-1 pb-4 text-2xl leading-7 font-bold tracking-wide",
    sectionHeading: "pt-1 pb-2 text-2xl font-bold tracking-wide cursor-pointer mb-2",
  };

  return (
    <Component
      className={cn(variantClasses[variant], className)}
      onClick={onClick}
      style={style}
      {...htmlContentProps}
    >
      {children}
    </Component>
  );
};

export default Text;
